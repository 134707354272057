import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl"
import {useDispatch, useSelector} from "react-redux"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {registerUser} from "../../redux/actions/Auth"
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import {useFormik} from 'formik'
import * as yup from 'yup'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {changeLocale} from "../../redux/actions/Common";
import {getLocale} from "../../utils/compute";
import MenuItem from "@mui/material/MenuItem";
import {capitalizeFirstLetter} from "../../utils/format";

const Register = ({intl}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const {register_success} = useSelector(({auth}) => auth)
    const {message, locale} = useSelector(({common}) => common)
    const {language} = useSelector(({list}) => list)

    const [showAlert, setShowAlert] = useState(false)
    const [alertMsg, setAlertMsg] = useState('')
    const [formTalent, setFormTalent] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        type: '',
        hear_about_us: ' '
    })

    let {type} = useParams();
    const params = new URLSearchParams(window.location.search)
    const first_name = params.get('first_name')
    const last_name = params.get('last_name')
    const email = params.get('email')
    console.log(email)
    const company_name = params.get('company_name')

    let formFieldsTalent = {
        first_name: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        last_name: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        type: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        email: yup
            .string()
            .email(intl.formatMessage({id: 'email.format.not.valid'}))
            .required(intl.formatMessage({id: 'this.field.is.required'})),
            hear_about_us: yup.string()
    }

    let formFieldsCompany = {
        first_name: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        last_name: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        type: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        company_name: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        email: yup
            .string()
            .email(intl.formatMessage({id: 'email.format.not.valid'}))
            .required(intl.formatMessage({id: 'this.field.is.required'})),
            hear_about_us: yup.string()
    }

    const validationSchema = yup.object(formTalent.type == 'company' ? formFieldsCompany : formFieldsTalent)

    const formik = useFormik({
        initialValues: formTalent,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleRegister()
        },
    })

    /*
    This is a hack. When coming form a link, this form is not updated.
    I dont know why. Remove at your own peril mortal
     */
    useEffect(() => {
        if (company_name) {
            setTimeout(function() {
                setFormTalent({
                    ...formTalent,
                    ['company_name']: company_name
                })
            }, 1000)
        }
    }, [company_name])


    useEffect(() => {
        setTimeout(function(){
            formTalent.type = type;
            if (first_name) {
                formTalent.first_name = first_name;
            }
            if (last_name) {
                formTalent.last_name = last_name;
            }
            if (email) {
                formTalent.email = email;
            }
            
            setFormTalent(formTalent)
        }, 500)
    }, [type])

    useEffect(() => {
        if (locale) {
            formTalent.locale = locale.short;
            setFormTalent(formTalent)
        }
    }, [locale])

    useEffect(() => {
        if (register_success?.user?.email) {
            const encodedEmail = encodeURIComponent(register_success.user.email);
            navigate('/login/new?email=' + encodedEmail);
        }
    }, [register_success])

    useEffect(() => {
        if (type) {
            handleTextChange(type, 'type')
        }
    }, [])

    useEffect(() => {
        if (message === 'incorrect_credentials') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'invalid.credentials'}))
        }

        if (message === 'account_blocked') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'account.blocked'}))
        }

        if (message === 'error') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'error.generic'}))
        }

        if (message === 'email_exist') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'email.exist'}))
        }
    }, [message])

    const handleTextChange = (value, field) => {
        setFormTalent({
            ...formTalent,
            [field]: value
        })
    }

    const handleRegister = () => {
        dispatch(registerUser(formTalent))
    }

    const howDidYouHearOptions = [
        { id: 1, value: 'website', label:  intl.formatMessage({id: 'websitehear'})},
        { id: 2, value: 'linkedin', label: intl.formatMessage({id: 'linkedin'})},
        { id: 3, value: 'online_ads', label: intl.formatMessage({id: 'online_ads'})},
        { id: 4, value: 'word_of_mouth', label: intl.formatMessage({id: 'word_of_mouth'})},
        { id: 5, value: 'referral', label: intl.formatMessage({id: 'referral'}) },
        { id: 6, value: 'event', label: intl.formatMessage({id: 'event'}) },
        { id: 7, value: 'other', label: intl.formatMessage({id: 'others'}) },
      ];

      
      

    return (
        <div className="login-container">
            <header className="py-2 flex flex-col space-y-5 justify-center items-center">
                <img src={require('./../../assets/images/logo.png')}
                     className="w-96" alt="Logo de images"/>

                <h1 className="py-3 px-6 rounded-full shadow-xxl font-semibold text-green-600 text-xl text-center">
                    {intl.formatMessage({id: 'register.message'})}
                </h1>
            </header>

            <main className="my-8 flex justify-center">
                <form
                    className="xs:w-11/12 sm:w-7/12 grid lg:grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-5 place-content-center  xl:grid"
                    onSubmit={formik.handleSubmit}>
                    <section className="shadow-xxl rounded-4xl md:block hidden">
                        <img src={require('./../../assets/images/register-step-one.jpg')} alt="Image de login"
                             className="rounded-4xl responsive_height"/>
                    </section>

                    <section className="shadow-xxl rounded-4xl flex justify-center flex-col p-8 md:w-full">

                        <section className="w-full flex-1 mt-4 m-x-4">
                            <FormControl className={'w-full'}>
                                <InputLabel id="demo-simple-select-standard-label">
                                    {intl.formatMessage({id: 'type'})}
                                </InputLabel>
                                <Select
                                    value={formTalent.type || ''}
                                    label={intl.formatMessage({id: 'type'})}
                                    onChange={(e) => handleTextChange(e?.target?.value, 'type')}
                                    className={'text-left'}
                                >
                                    <MenuItem key={1} value={'talent'}>
                                        {intl.formatMessage({id: 'a.talent'})}
                                    </MenuItem>
                                    <MenuItem key={2} value={'company'}>
                                        {intl.formatMessage({id: 'a.company'})}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </section>
                       



                        {
                            formTalent.type == 'company' ?

                                company_name ?
                                    <section className="w-full flex-1 mt-4 m-x-4">
                                        <TextField
                                            label={intl.formatMessage({id: 'company.name'})}
                                            className={'w-full'}
                                            defaultValue={company_name || ''}
                                            id="formatted-numberformat-input"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <i className={'fa-solid fa-building text-secondary'}></i>
                                                </InputAdornment>,
                                            }}
                                            onChange={(e) => handleTextChange(e?.target?.value, 'company_name')}
                                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                                            helperText={formik.touched.company_name && formik.errors.company_name}
                                        />
                                    </section>
                                    :
                                    <section className="w-full flex-1 mt-4 m-x-4">
                                        <TextField
                                            label={intl.formatMessage({id: 'company.name'})}
                                            className={'w-full'}
                                            defaultValue={formik.values.company_name || ''}
                                            id="formatted-numberformat-input"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <i className={'fa-solid fa-building text-secondary'}></i>
                                                </InputAdornment>,
                                            }}
                                            onChange={(e) => handleTextChange(e?.target?.value, 'company_name')}
                                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                                            helperText={formik.touched.company_name && formik.errors.company_name}
                                        />
                                    </section>
                                : ''
                        }

                        <section className="w-full flex-1 mt-4 m-x-4">
                            <TextField
                                label={intl.formatMessage({id: 'first.name'})}
                                className={'w-full'}
                                value={formTalent?.first_name}
                                id="formatted-numberformat-input"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <i className={'fa-solid fa-circle-user text-secondary'}></i>
                                    </InputAdornment>,
                                }}
                                onChange={(e) => handleTextChange(e?.target?.value, 'first_name')}
                                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                helperText={formik.touched.first_name && formik.errors.first_name}
                            />
                        </section>

                        <section className="w-full flex-1 mt-4">
                            <TextField
                                label={intl.formatMessage({id: 'last.name'})}
                                className={'w-full'}
                                value={formTalent?.last_name}
                                id="formatted-numberformat-input"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <i className={'fa-regular fa-user text-secondary'}></i>
                                    </InputAdornment>,
                                }}
                                onChange={(e) => handleTextChange(e?.target?.value, 'last_name')}
                                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                helperText={formik.touched.last_name && formik.errors.last_name}
                            />
                        </section>

                        <section className="w-full flex-1 mt-4">
                                <TextField
                                    label={intl.formatMessage({id: 'email'})}
                                    className={'w-full'}
                                    value={formTalent?.email}
                                    //defaultValue={formik.values.email || ''}
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <i className={'fa fa-envelope text-secondary'}></i>
                                        </InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        let fieldValue = e?.target?.value
                                        handleTextChange(fieldValue.trim(), 'email')
                                    }}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                        </section>
                        <section className="w-full flex-1 mt-4 m-x-4">
                            <FormControl className={'w-full'}>
                                <InputLabel id="demo-simple-select-standard-label">
                                    {intl.formatMessage({id: 'How_did_you_hear_about_us?'})}
                                </InputLabel>
                                <Select
                                    value={formTalent.hear_about_us || ''}
                                    label={intl.formatMessage({id: 'How_did_you_hear_about_us?'})}
                                    onChange={(e) => handleTextChange(e?.target?.value, 'hear_about_us')}
                                    className={'text-left'}
                                >
                                    {howDidYouHearOptions?.map(option => {
                                        return (
                                            <MenuItem key={option.id} value={option.value}>
                                                {capitalizeFirstLetter(option.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </section>


                        <div className="flex-1 flex justify-center items-end mt-4">
                            <button type="submit" className="px-16 py-2 bg-secondary rounded-full text-white">
                                {intl.formatMessage({id: 'validate'})}
                            </button>
                        </div>

                        <div className="flex-1 flex justify-center items-end mt-4 text-xs">
                            <div>
                                {intl.formatMessage({id: 'by.click.validate'})}
                                <a href="/conditions-generales-de-vente-et-dutilisation-de-talenteum.pdf"
                                   className="text-secondary"
                                   target="_blank"> {intl.formatMessage({id: 'cgu.cgv'})}</a> {intl.formatMessage({id: 'and.special'})}
                                <a href="/centre-de-protection-des-donnees.pdf" className="text-secondary"
                                   target="_blank"> {intl.formatMessage({id: 'policy.confidentiality'})}</a> {intl.formatMessage({id: 'of.breedj'})}
                            </div>
                        </div>

                        <hr className={'mt-4'} />

                        <button type="button"
                                className="px-8 py-2 text-secondary border-2 border-secondary rounded-full flex items-center justify-center w-full mt-4"
                                onClick={() => {
                                    window.location.replace(process.env.REACT_APP_API_URL + 'linkedin/auth/redirect')
                                }}
                        >
                            <img src={require('./../../assets/images/linkedin_logo_initials.png')} alt="Logo linkedin"
                                 className="rounded-4xl responsive_height !w-6 mr-4"/>
                            {intl.formatMessage({id: 'register.linkedin'})}
                        </button>
                    </section>

                    <section></section>

                    <section className="flex justify-center items-center space-x-4 text-xs">
                        <Link to={'/login'} className="px-4 py-1 rounded-full bg-secondary cursor-pointer text-white">
                            {intl.formatMessage({id: 'login'})}
                        </Link>
                        <FormControl className={'ml-4'}>
                            <InputLabel id="demo-simple-select-standard-label">
                                {intl.formatMessage({id: 'language'})}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={locale.short}
                                label={intl.formatMessage({id: 'language'})}
                                onChange={(e) => dispatch(changeLocale(getLocale(e.target.value)))}
                                className={'text-left'}
                                size={'small'}
                            >
                                {language?.filter(function (lang) {
                                    if (lang.code == "en" || lang.code == 'fr') {
                                        return true
                                    }
                                }).map(option => {
                                    return (
                                        <MenuItem key={option.code} value={option.code}>
                                            {capitalizeFirstLetter(option.name)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </section>
                </form>


            </main>

            <Snackbar
                open={showAlert} //autoHideDuration={6000}
                onClose={() => setShowAlert(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <MuiAlert onClose={() => setShowAlert(false)} severity="error" sx={{width: '100%'}}>
                    {alertMsg}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default injectIntl(Register)
