import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { storeTalentRating } from '../../../redux/actions/Talent';
import { useLocation, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ValidateButton from "../../../components/common/ValidateButton";
import { changeLocale } from '../../../redux/actions/Common';
import { getLocale } from '../../../utils/compute';


const Rating = ({intl}) => {
    const [rating, setRating] = useState(null);
    const [comment, setComment] = useState('');
    const dispatch = useDispatch();
    const { user} = useSelector(({auth}) => auth);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const index = queryParams.get('index');
    const token = queryParams.get('token');
    let lang = new URLSearchParams(window.location.search)?.get('lang') || 'en';
    const navigate = useNavigate();


    useEffect(() => {
      setRating(Number(index))
    },[index])

    useEffect(() => {
      dispatch(changeLocale(getLocale(lang)))
    }, [lang])

    const handleCommentChange = (e) => {
      setComment(e.target.value);
    };

    const handleSubmitComment = () => {
      dispatch(storeTalentRating(token,{rating: index,commentaire: comment}))
    }

    const handleRating = (rating) => {
      setRating(rating);
      dispatch(storeTalentRating(token,{rating,commentaire: comment}))
      navigate(`?index=${rating}`);
    };

    
  
    return (
      <div className="flex justify-center  w-full min-h-screen bg-gray-100">
        <div className="shadow-2xl rounded-2xl bg-white flex flex-col mt-24 items-center w-[100%] lg:w-[60%] h-fit p-8 m-4">
          <h2 className="text-xl font-semibold mb-6">
            {intl.formatMessage({id: "rating.title"})}
          </h2>
          <div className="flex justify-between w-full px-24">
            <span className="text-sm text-gray-500">{intl.formatMessage({id: "not.at.all.likely"})}</span>
            <span className="text-sm text-gray-500">{intl.formatMessage({id: "extremely.likely"})}</span>
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-4 justify-center mt-4 w-full">
            <div className="flex justify-center space-x-4 mb-4 lg:mb-0 lg:flex-row w-full lg:w-auto">
              {Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handleRating(index + 1)}
                  className={`w-12 h-12 rounded-xl border ${
                    rating === index + 1
                      ? 'bg-blue-500 text-white border-blue-500'
                      : 'border-gray-300 text-gray-700'
                  } flex justify-center items-center hover:bg-blue-100`}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            <div className="flex justify-center space-x-4 lg:flex-row w-full lg:w-auto">
              {Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 5}
                  onClick={() => handleRating(index + 6)}
                  className={`w-12 h-12 rounded-xl border ${
                    rating === index + 6
                      ? 'bg-blue-500 text-white border-blue-500'
                      : 'border-gray-300 text-gray-700'
                  } flex justify-center items-center hover:bg-blue-100`}
                >
                  {index + 6}
                </button>
              ))}
            </div>
          </div>

          {rating !== null && (
            <p className="mt-6 text-gray-700">
              {intl.formatMessage({id: "you.selected"})} <span className="font-bold">{rating}</span> {intl.formatMessage({id: "as.your.rating"})}.
            </p>
          )}


          <div className="mt-6 w-full flex flex-col gap-3">
              <span>
                {intl.formatMessage({id:"rating.comment.title"})}
              </span>
              <textarea
                  value={comment}
                  onChange={handleCommentChange}
                  rows="4"
                  placeholder={intl.formatMessage({id:"rating.textarea.placeholder"})}
                  className="w-full p-4 border border-gray-300 rounded-xl resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              />

              <ValidateButton onClickSubmit={handleSubmitComment} />
            </div>
        </div>
      </div>
    );
}

export default injectIntl(Rating)
