import React, {useEffect, useRef, useState} from 'react'
import AppRoutes from '../../routes/index'
import {injectIntl} from "react-intl"
import {useDispatch, useSelector} from 'react-redux'
import {getContact} from '../../redux/actions/Company'
import {graphqlContactFields} from "../../utils/constant"
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {userSignOut} from "../../redux/actions/Auth";
import Button from "@mui/material/Button";
import {TawkToConfig} from "../../utils/tawk_to";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from '@mui/icons-material/Search';
import {checkAutoProfileFinished} from "../../redux/api/Talent";
import {wait} from "../../utils/compute";
import {checkJob, clearJobId} from "../../redux/actions/Chatgpt";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {pagesNav, pagesCompanyNav, pagesAccNav} from "../../utils/nav_company";
import Divider from '@mui/material/Divider';
import {capitalizeFirstLetter} from "../../utils/format";
import CreateTicket from '../../components/common/createTicket'


const CompanyLayout = ({intl}) => {
    const tawkMessengerRef = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()
    const {pathname} = useLocation();
    const {token, user} = useSelector(({auth}) => auth)
    const {reupdate_contact, contact} = useSelector(({company}) => company)
    const {documents} = useSelector(({file}) => file)
    const {job_offer_id, loading_job, recheck_job, loading_job_done, loading_job_error} = useSelector(({chatgpt}) => chatgpt)

    const [currentNav, setCurrentNav] = useState("")
    const [showTalkTo, setShowTalkTo] = React.useState(false);

    const [newNavigation, setNewNavigation] = React.useState(null);
    const [anchorElDynamic, setAnchorElDynamic] = React.useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [openDynamic, setOpenDynamic] = React.useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = (name) => {
        setAnchorElNav(null);
        navigate('/company/dashboard?tab=' + name);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const Alert = React.forwardRef(function Alert(
        props, ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleTawkTo = () => {
        setShowTalkTo(true);
        setTimeout(function() {
            tawkMessengerRef.current.toggle();
        }, 500);
    }

    useEffect(() => {
        if (user && user.type == 'company') {
            dispatch(getContact(token, `
            { 
                contactById(id: "${user.relation}") {
                    ${graphqlContactFields}
                }
            }
        `))
        }

    }, [user, reupdate_contact, documents])

    useEffect(() => {
        if (loading_job && recheck_job > 0) {
            wait(10000).then(() => {
                dispatch(checkJob(token, job_offer_id))
            })
        }
    }, [loading_job, recheck_job])

    useEffect(() => {
        if (newNavigation) {
            setTimeout(function() {
                navigate(newNavigation);
                setNewNavigation(null)
            }, 500);
        }

    }, [newNavigation])

    useEffect(() => {
        if (pathname) {
            let allPaths = pagesNav.concat(pagesCompanyNav).concat(pagesAccNav);
            for (let i = 0; i < allPaths.length; i++) {
                let path = allPaths[i];
                if (path.subs) {
                    for (let j = 0; j < path.subs.length; j++) {
                        let sub = path.subs[j];
                        if (sub.path === pathname) {
                            setCurrentNav(path.name)
                            return;
                        }
                    }
                }
            }
        } else {
            setCurrentNav("")
        }

    }, [pathname])

    const renderSubs = (subs) => {
        let render = (
            <div style={{minWidth: '180px'}}>
                {subs.map((sub, index) => (
                    <React.Fragment key={index + 10}>
                        <MenuItem
                            className={pathname === sub.path ? 'item-nav bg-secondary text-white hover:text-secondary' : 'item-nav'}
                            key={index} onClick={() => {
                                setOpenDynamic(null);
                                setAnchorElUser(null);
                                setNewNavigation(sub.path);
                            }
                        }>
                            {intl.formatMessage({id: sub.name})}
                        </MenuItem>
                        {sub?.break ? <Divider /> : null}
                    </React.Fragment>
                ))}
            </div>
        )

        return render;
    }

    const renderAdminNav = () => {
        let render = (
            <React.Fragment>
                {pagesCompanyNav.map((nav, index) => (
                    <React.Fragment key={index + 100}>
                        <Button
                            key={'but' + index}
                            sx={{ color: 'white', display: 'block', midWidth: '180px', height: '40px' }}
                            className={currentNav === nav.name ? 'flex items-center font-sans text-white hover:text-black bg-secondary rounded-xl text-xxl normal-case' :  'flex items-center font-sans text-secondary text-xxl normal-case'}
                            onClick={(event) => {
                                setAnchorElDynamic(event.currentTarget);
                                setOpenDynamic(nav.name)
                            }}
                        >
                            {intl.formatMessage({id: nav.name})}
                        </Button>
                        <Menu
                            key={'menu' + index}
                            id="basic-menu-dynamic"
                            anchorEl={anchorElDynamic}
                            open={openDynamic === nav.name}
                            onClose={() => {
                                setOpenDynamic(null)
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {renderSubs(nav.subs)}
                        </Menu>
                    </React.Fragment>
                ))}
            </React.Fragment>
        )

        return render;
    }

    return (
        <div className={'bdj-body-container justify-center relative'} id={'nav_container'}>
            {contact && contact?.profile_status != 'new' ?
                <div>
                    <AppBar position="static" className={' bg-white text-secondary'}>
                        <Container maxWidth="xxl">
                            <Toolbar disableGutters>

                                <img
                                    src={require('./../../assets/images/logo.png')} alt="Logo de Talenteum"
                                    className={'ml-16 md:ml-24 w-40 flex py-2 cursor-pointer block'}
                                    onClick={() => {
                                        setCurrentNav("")
                                        navigate('/company/dashboard')
                                    }}
                                />

                                <Box className="flex-grow justify-end text-xl 2md:hidden flex">
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={() => {
                                            handleCloseNavMenu(null)
                                        }}
                                        sx={{
                                            display: {xs: 'block', md: 'none'},
                                        }}
                                    >
                                        {pagesNav.map((page, index) => (
                                            <MenuItem key={index} onClick={() => {
                                                handleCloseNavMenu(page.name)
                                            }}>
                                                <Typography
                                                    textAlign="center">{intl.formatMessage({id: page.name})}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>

                                <Box className="flex-grow items-center justify-evenly text-xl 2md:flex hidden">
                                    {pagesNav.map((nav, index) => (
                                        <React.Fragment key={index + 10}>
                                            <Button
                                                key={'but' + index}
                                                sx={{
                                                    color: 'white',
                                                    display: 'block',
                                                    midWidth: '180px',
                                                    height: '40px'
                                                }}
                                                className={currentNav === nav.name ? 'flex items-center font-sans text-white hover:text-black bg-secondary rounded-xl text-xxl normal-case' : 'flex items-center font-sans text-secondary text-xxl normal-case'}
                                                onClick={(event) => {
                                                    setAnchorElDynamic(event.currentTarget);
                                                    setOpenDynamic(nav.name)
                                                }}
                                            >
                                                {intl.formatMessage({id: nav.name})}
                                            </Button>
                                            <Menu
                                                key={'menu' + index}
                                                id="basic-menu-dynamic"
                                                anchorEl={anchorElDynamic}
                                                open={openDynamic === nav.name}
                                                onClose={() => {
                                                    setOpenDynamic(null)
                                                }}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                {renderSubs(nav.subs)}
                                            </Menu>
                                        </React.Fragment>
                                    ))}

                                    {contact?.is_admin ? renderAdminNav() : null}
                                </Box>

                                <Box sx={{flexGrow: 0}}>
                                    <Button
                                        key={'but5'}
                                        sx={{color: 'white', display: 'block', maxWidth: '180px', height: '40px'}}
                                        className={currentNav === pagesAccNav[0].name ? 'hidden 2md:flex items-center font-sans text-white hover:text-black bg-green-600 rounded-xl text-xxl capitalize truncate mr-24' :  'hidden 2md:flex items-center font-sans text-green-600 text-xxl capitalize truncate mr-24'}
                                        onClick={handleOpenUserMenu}
                                    >
                                        {contact?.first_name}
                                    </Button>
                                    <Menu
                                        sx={{mt: '45px'}}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {renderSubs(pagesAccNav[0]?.subs)}
                                    </Menu>
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </div>
                : ''}
            <AppRoutes/>
            {contact && contact?.profile_status != 'new' ?
                <footer className="py-4" style={{
                    boxShadow: "0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12)"
                }}>
                    <ul className="flex flex-col space-y-2 md:flex-row md:space-y-0 items-center justify-evenly">
                        {/* <li>
                        <a href={'#'} className="tawk_open" onClick={handleTawkTo}>
                                {intl.formatMessage({id: 'contact.us'})}
                            </a>
                        </li> */}

                        <li>
                            <a href="/centre-de-protection-des-donnees.pdf"
                               target="_blank" rel="noreferrer">{intl.formatMessage({id: 'protect.data'})}</a>
                        </li>

                        <li>
                            <a href="/conditions-generales-de-vente-et-dutilisation-de-talenteum.pdf"
                               target="_blank" rel="noreferrer">{intl.formatMessage({id: 'cgu'})}</a>
                        </li>

                        <li>Talenteum &copy;</li>
                    </ul>
                </footer>
            : '' }

            {showTalkTo ?
            <TawkMessengerReact propertyId={TawkToConfig.propertyId} widgetId={TawkToConfig.widgetId} ref={tawkMessengerRef}/>
                : ''}
            <CreateTicket />
            {/* Remove snackbars*/}
            {/*<Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={loading_job}
                onClose={() => {

                }}
            >
                <Alert icon={<CircularProgress size={'1rem'} />} severity="warning" sx={{ width: '100%' }}>
                    <div className={'flex items-center'}>
                        {intl.formatMessage({id: 'creating.job.offer'})}...
                    </div>
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={loading_job_done}
                onClose={() => {

                }}
                className={'cursor-pointer'}
            >
                <Alert icon={<SearchIcon size={'1rem'} />} severity="success" sx={{ width: '100%' }} onClick={() => {
                    navigate(`/company/job_offer/fiche/${job_offer_id}?redirect=1`)
                    dispatch(clearJobId());
                }}>
                    <div className={'flex items-center'}>
                        {intl.formatMessage({id: 'creating.job.offer.done'})}
                    </div>
                </Alert>
            </Snackbar>
            */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={loading_job_error}
                onClose={() => {

                }}
                className={'cursor-pointer'}
            >
                <Alert icon={<SearchIcon size={'1rem'} />} severity="error" sx={{ width: '100%' }} onClick={() => {
                    navigate(`/company/job_offer/auto`)
                    dispatch(clearJobId());
                }}>
                    <div className={'flex items-center'}>
                        {intl.formatMessage({id: 'creating.job.offer.fail'})}
                    </div>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default injectIntl(CompanyLayout)
